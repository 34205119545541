import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import { getFilmStillUrlForExperiment } from '@app/api/services/experiments';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import FilmTileWithImpressionTracking from '@app/components/film-tile/default/FilmTileWithImpressionTracking';

const ROTATE_FILMS_INTERVAL = 5000;
const EXIT_ENTER_DELAY = 1000;
const ANIMATION_DURATION = 400;
const PRELOAD_DELAY = 1000;

type RotatingFilmTileProps = {
  films: Film[];
  delay?: number;
  isVisible?: boolean;
  snowplowElement: string;
  snowplowRelatedIds?: {
    promo_campaign: string;
  };
  showCenteredPlayButtonAndNoHover: boolean;
};

const RotatingFilmTile = ({
  films,
  delay = 0,
  isVisible = true,
  snowplowElement,
  snowplowRelatedIds,
  showCenteredPlayButtonAndNoHover,
}: RotatingFilmTileProps) => {
  const filmIndex = useRef(0);
  const [show, setShow] = useState(false);
  const activeSubscriber = useAppSelector(state => state.user.activeSubscriber);
  const experiments = useAppSelector(state => state?.experiments?.experiments);

  const getNextFilmIndex = () => (filmIndex.current + 1) % films.length;

  const preloadFilmImage = (i: number) => {
    const filmStillImageUrl = getFilmStillUrlForExperiment(
      experiments,
      films[i],
    );
    const src = `${filmStillImageUrl}?size=400x`;
    const image = new Image();
    image.src = src;
  };

  const preloadNextFilmImage = () => {
    setTimeout(() => {
      const nextFilmIndex = getNextFilmIndex();
      preloadFilmImage(nextFilmIndex);
    }, PRELOAD_DELAY);
  };

  useEffect(() => {
    let initialDelayTimeoutId: NodeJS.Timeout = null;
    let rotationIntervalId: NodeJS.Timeout = null;
    let fadeOutLastAndInNextTimeoutId: NodeJS.Timeout = null;

    const rotateFilm = () => {
      setShow(false);
      fadeOutLastAndInNextTimeoutId = setTimeout(() => {
        filmIndex.current = getNextFilmIndex();
        setShow(true);
        preloadNextFilmImage();
      }, EXIT_ENTER_DELAY);
    };

    const clearTimers = () => {
      clearTimeout(initialDelayTimeoutId);
      clearInterval(rotationIntervalId);
      clearTimeout(fadeOutLastAndInNextTimeoutId);
    };

    if (films.length) {
      if (isVisible) {
        initialDelayTimeoutId = setTimeout(() => {
          // initial show
          setShow(true);
          // queue up rotation
          rotationIntervalId = setInterval(rotateFilm, ROTATE_FILMS_INTERVAL);
          preloadNextFilmImage();
        }, delay);
      } else {
        // pause, and if already showing then increment, ready for next time it becomes visible
        clearTimers();
        if (show) {
          setShow(false);
          filmIndex.current = getNextFilmIndex();
        }
        preloadFilmImage(filmIndex.current);
      }
    }

    return clearTimers;
  }, [isVisible, films.length]);

  const film = films[filmIndex.current];
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  if (!films.length) {
    return <LoadingTile />;
  }

  const trackEvent = (clickType: string) =>
    trackSnowplowClickEvent({
      data: {
        clickType,
        element: snowplowElement,
      },
      extraData: {
        film_id: film.id,
        ...snowplowRelatedIds,
      },
      film,
    });

  return (
    <CSSTransition
      in={show && isVisible}
      timeout={ANIMATION_DURATION}
      classNames="film-tile"
      unmountOnExit
    >
      <FilmTileContainer>
        <FilmTileWithImpressionTracking
          film={film}
          onTileClick={() => trackEvent('film_tile')}
          onPlayButtonClick={() => activeSubscriber && trackEvent('film_play')}
          forceWithoutPreviewClipPlayer
          showWatchListButton={false}
          showRatingButton={false}
          showCenteredPlayButtonAndNoHover={showCenteredPlayButtonAndNoHover}
        />
      </FilmTileContainer>
    </CSSTransition>
  );
};

export default RotatingFilmTile;

const LoadingTile = styled.div`
  height: 100%;
  background-color: ${props => props.theme.color.midGray};
`;

const FilmTileContainer = styled.div`
  height: 100%;
  /* animations */
  &.film-tile-enter {
    opacity: 0;
    margin-top: 118px;
  }
  &.film-tile-enter-active,
  &.film-tile-exit {
    opacity: 1;
    margin-top: 0;
  }
  &.film-tile-exit-active {
    opacity: 0;
    margin-top: 118px;
  }
  &.film-tile-enter-active,
  &.film-tile-exit-active {
    transition-property: margin-top, opacity;
    /* should match ANIMATION_DURATION */
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  }
`;
