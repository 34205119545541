import { useEffect, useState } from 'react';

import {
  getValueAtBreakpoint,
  StringOrNumberOrBoolean,
} from '@app/services/breakpoints';

type ValueAtBreakpointProps = {
  children: (props: { currentValue: StringOrNumberOrBoolean }) => JSX.Element;
  defaultValue: StringOrNumberOrBoolean;
  valueAtMobile?: StringOrNumberOrBoolean;
  valueAtTablet?: StringOrNumberOrBoolean;
  valueAtDesktop?: StringOrNumberOrBoolean;
  valueAtWide?: StringOrNumberOrBoolean;
};

const ValueAtBreakpoint = ({
  children,
  defaultValue,
  valueAtMobile = false,
  valueAtTablet = false,
  valueAtDesktop = false,
  valueAtWide = false,
}: ValueAtBreakpointProps) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const onResize = () => {
    const newValue = getValueAtBreakpoint({
      defaultValue,
      valueAtMobile,
      valueAtTablet,
      valueAtDesktop,
      valueAtWide,
    });

    setCurrentValue(newValue);
  };
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return children({ currentValue });
};

export default ValueAtBreakpoint;
