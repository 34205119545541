import { isClient } from '@app/services/utils';

import { mqNew } from '@app/themes/mubi-theme';

const MAX_SAFE_INTEGER = 9007199254740991;

export const checkScreenCurrentlyLargerThanWidth = width => {
  if (isClient() && window.matchMedia) {
    const wideLargeWidthCheck = window.matchMedia(`(min-width: ${width})`);
    if (wideLargeWidthCheck.matches) {
      return true;
    }
    return false;
  }
  return null;
};

export const checkScreenCurrentlySmallerThanWidth = width => {
  if (isClient() && window.matchMedia) {
    const smallWidthCheck = window.matchMedia(`(max-width: ${width})`);
    if (smallWidthCheck.matches) {
      return true;
    }
    return false;
  }
  return null;
};

const checkScreenCurrentlyBetweenTwoWidths = (smallerWidth, largerWidth) => {
  if (isClient() && window.matchMedia) {
    const mobileWidthCheck = window.matchMedia(
      `(max-width: ${largerWidth}) and (min-width: ${smallerWidth})`,
    );
    if (mobileWidthCheck.matches) {
      return true;
    }
    return false;
  }
  return null;
};

export const isMobileWidth = () =>
  checkScreenCurrentlySmallerThanWidth(mqNew.tablet);

export const isGreaterThanMobileWidth = () => {
  if (isClient() && window.matchMedia) {
    if (isMobileWidth()) {
      return false;
    }
    return true;
  }
  return null;
};

export const isTabletWidth = () =>
  checkScreenCurrentlyBetweenTwoWidths(mqNew.tablet, mqNew.desktop);

export const isGreaterThanTabletWidth = () =>
  checkScreenCurrentlyLargerThanWidth(mqNew.tablet);

export const isBetweenTabletAndDesktop = () =>
  checkScreenCurrentlyBetweenTwoWidths(mqNew.tablet, mqNew.desktop);

export const isSmallerThanDesktopWidth = () =>
  checkScreenCurrentlySmallerThanWidth(mqNew.desktop);

export const isGreaterThanDesktopWidth = () =>
  checkScreenCurrentlyLargerThanWidth(mqNew.desktop);

export const isDesktopWidth = () =>
  checkScreenCurrentlyBetweenTwoWidths(mqNew.desktop, mqNew.wide);

export const isBetweenDesktopAndWide = () =>
  checkScreenCurrentlyBetweenTwoWidths(mqNew.desktop, mqNew.wide);

export const isWideWidth = () =>
  checkScreenCurrentlyBetweenTwoWidths(mqNew.wide, mqNew.wideLarge);

export const isWideLargeWidth = () =>
  checkScreenCurrentlyLargerThanWidth(mqNew.wideLarge);

export const isGreaterThanWide = () =>
  checkScreenCurrentlyLargerThanWidth(mqNew.wide);

export type StringOrNumberOrBoolean = string | number | boolean;

type ValuesAt = {
  valueAtMobile?: StringOrNumberOrBoolean;
  valueAtTablet?: StringOrNumberOrBoolean;
  valueAtDesktop?: StringOrNumberOrBoolean;
  valueAtWide?: StringOrNumberOrBoolean;
  valueAtWideLarge?: StringOrNumberOrBoolean;
  defaultValue?: StringOrNumberOrBoolean;
};
export const getValueAtBreakpoint = ({
  valueAtMobile,
  valueAtTablet,
  valueAtDesktop,
  valueAtWide,
  valueAtWideLarge,
  defaultValue,
}: ValuesAt): StringOrNumberOrBoolean => {
  let currentValue = defaultValue;
  if (isWideLargeWidth() && (valueAtWideLarge || valueAtWide)) {
    currentValue = valueAtWideLarge || valueAtWide;
  }
  if (isWideWidth() && valueAtWide) {
    currentValue = valueAtWide;
  }
  if (isDesktopWidth() && valueAtDesktop) {
    currentValue = valueAtDesktop;
  }
  if (isTabletWidth() && valueAtTablet) {
    currentValue = valueAtTablet;
  }
  if (isMobileWidth() && valueAtMobile) {
    currentValue = valueAtMobile;
  }
  return currentValue;
};

export const mapBreakpointsToValueToParamArray = breakpoints => {
  // The breakpoints param should have the following shape:
  // const breakpointsShape = PropTypes.arrayOf(
  //   PropTypes.shape({
  //     fromWidth: PropTypes.number,
  //     toWidth: PropTypes.number,
  //     value: PropTypes.number,
  //   }),
  // );
  // The mapBreakpointsToValueToParamArray function returns the value for
  // the breakpoint the browser is currently in.

  const orderedBreakpointWidths = Object.keys(breakpoints).sort((a, b) =>
    parseInt(a, 10) > parseInt(b, 10) ? 1 : -1,
  );

  return orderedBreakpointWidths.map((breakpointWidth, index) => {
    if (index === 0) {
      return {
        fromWidth: parseInt(breakpointWidth, 10),
        toWidth: parseInt(orderedBreakpointWidths[index + 1], 10),
        value: breakpoints[breakpointWidth].slidesPerGroup,
      };
    }
    if (index === orderedBreakpointWidths.length - 1) {
      return {
        fromWidth: parseInt(breakpointWidth, 10),
        toWidth: MAX_SAFE_INTEGER,
        value: breakpoints[breakpointWidth].slidesPerGroup,
      };
    }
    return {
      fromWidth: parseInt(breakpointWidth, 10),
      toWidth: parseInt(orderedBreakpointWidths[index + 1], 10),
      value: breakpoints[breakpointWidth].slidesPerGroup,
    };
  });
};

export const getValueAtCustomBreakpoint = customBreakpoints => {
  if (isClient()) {
    const currentBreakpoint = customBreakpoints.find(customBreakpoint =>
      checkScreenCurrentlySmallerThanWidth(`${customBreakpoint.toWidth}px`),
    );
    if (currentBreakpoint) {
      return currentBreakpoint.value;
    }
  }
  return null;
};
