import { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import ValueAtBreakpoint from '@app/components/breakpoints/ValueAtBreakpoint';
import RotatingFilmTile from '@app/components/RotatingFilmTile';

const STAGGER_DELAY = 200;

type RotatingFilmTileGroupProps = {
  films: Film[];
  children: (el: ReactNode) => ReactNode;
  snowplowElement: string;
  snowplowRelatedIds?: { promo_campaign: string };
  showCenteredPlayButtonAndNoHover?: boolean;
};

const RotatingFilmTileGroup = ({
  films,
  children,
  snowplowElement,
  snowplowRelatedIds,
  showCenteredPlayButtonAndNoHover = true,
}: RotatingFilmTileGroupProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '100px 0px 100px 0px',
  });

  return (
    <div ref={ref}>
      <ValueAtBreakpoint
        defaultValue={0}
        valueAtMobile={1}
        valueAtTablet={2}
        valueAtDesktop={3}
        valueAtWide={3}
      >
        {({
          currentValue: numberOfFilmsToDisplay,
        }: {
          currentValue: number;
        }) => (
          <Container key={numberOfFilmsToDisplay}>
            {numberOfFilmsToDisplay > 0 &&
              children(
                <RotatingFilmTile
                  films={selectFilms(films, 0, numberOfFilmsToDisplay)}
                  isVisible={inView}
                  snowplowElement={snowplowElement}
                  snowplowRelatedIds={snowplowRelatedIds}
                  showCenteredPlayButtonAndNoHover={
                    showCenteredPlayButtonAndNoHover
                  }
                />,
              )}
            {numberOfFilmsToDisplay > 1 &&
              children(
                <RotatingFilmTile
                  films={selectFilms(films, 1, numberOfFilmsToDisplay)}
                  delay={STAGGER_DELAY}
                  isVisible={inView}
                  snowplowElement={snowplowElement}
                  snowplowRelatedIds={snowplowRelatedIds}
                  showCenteredPlayButtonAndNoHover={
                    showCenteredPlayButtonAndNoHover
                  }
                />,
              )}
            {numberOfFilmsToDisplay > 2 &&
              children(
                <RotatingFilmTile
                  films={selectFilms(films, 2, numberOfFilmsToDisplay)}
                  delay={2 * STAGGER_DELAY}
                  isVisible={inView}
                  snowplowElement={snowplowElement}
                  snowplowRelatedIds={snowplowRelatedIds}
                  showCenteredPlayButtonAndNoHover={
                    showCenteredPlayButtonAndNoHover
                  }
                />,
              )}
          </Container>
        )}
      </ValueAtBreakpoint>
    </div>
  );
};

export default RotatingFilmTileGroup;

const selectFilms = (films: Film[], offset: number, increment: number) =>
  films.filter((_, i) => (i - offset) % increment === 0);

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
