import { ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';

import { FilmImpression } from '@app/services/snowplow';

import useSnowplowFilmImpressionTracker from '@app/hooks/snowplow/useSnowplowFilmImpressionTracker';

type TrackFilmImpressionProps = {
  children: ReactNode;
  disableTracking?: boolean;
} & FilmImpression;

const TrackFilmImpression = ({
  children,
  filmId,
  isWatchable,
  collectionSlug,
  filmGroupId,
  moduleType,
  sectionNumber,
  indexInSection,
  horizontalPosition,
  verticalPosition,
  searchRank,
  disableTracking = false,
}: TrackFilmImpressionProps) => {
  const doTrackSnowplowFilmImpression = useSnowplowFilmImpressionTracker();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (!disableTracking) {
      if (inView && filmId) {
        doTrackSnowplowFilmImpression({
          filmId,
          isWatchable,
          moduleType,
          collectionSlug,
          filmGroupId,
          sectionNumber,
          indexInSection,
          horizontalPosition,
          verticalPosition,
          searchRank,
        });
      }
    }
  }, [inView]);

  return <Container ref={ref}>{children}</Container>;
};

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export default TrackFilmImpression;
