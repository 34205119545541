import { SyntheticEvent } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { Film, getFilmLink } from '@app/api/resources/Film';
import { IndustryEventEntry } from '@app/api/resources/IndustryEvent';

import { FilmTrackingDetails } from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const FilmTileWithoutPreviewPlayer = dynamic(
  () =>
    import(
      /* webpackChunkName: "FilmTileWithoutPreviewPlayer" */
      '@app/components/film-tile/default/tile-without-player/FilmTileWithoutPreviewPlayer'
    ),
);

const FilmTileWithPreviewPlayer = dynamic(
  () =>
    import(
      /* webpackChunkName: "FilmTileWithPreviewPlayer" */
      '@app/components/film-tile/default/tile-with-player/FilmTileWithPreviewPlayer'
    ),
);

export const UNPLAYABLE_FILM_TILE_SNOWPLOW_ELEMENT = 'film_tile_unplayable';
export const PLAYABLE_FILM_TILE_SNOWPLOW_ELEMENT = 'film_tile_playable';

export type FilmTileProps = {
  film: Film;
  filmGroupColour?: string;
  showWatchListButton?: boolean;
  showRatingButton?: boolean;
  stillImagePixelWidth?: number;
  onPlayButtonClick: () => void;
  onTileClick: (e?: SyntheticEvent) => void;
  showProgressBar?: boolean;
  progressPercentage?: number;
  listPosition?: number;
  programmingDateLabel?: string;
  backgroundColor?: string;
  forceWithoutPreviewClipPlayer?: boolean;
  onWatchlistClick?: () => void;
  filmTrackingDetails?: FilmTrackingDetails;
  awardsToShow?: IndustryEventEntry[];
  showCenteredPlayButtonAndNoHover?: boolean;
  showRatingMode?: boolean;
};

const FilmTile = ({
  film,
  filmGroupColour = '',
  onPlayButtonClick,
  showWatchListButton = true,
  showRatingButton = true,
  showCenteredPlayButtonAndNoHover = false,
  stillImagePixelWidth = 400,
  onTileClick,
  showProgressBar = false,
  progressPercentage = 0,
  listPosition = null,
  programmingDateLabel = null,
  backgroundColor = null,
  forceWithoutPreviewClipPlayer = false,
  onWatchlistClick,
  filmTrackingDetails,
  awardsToShow = null,
  showRatingMode = false,
}: FilmTileProps) => {
  const router = useRouter();

  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);

  const isPlayable = film.consumable?.availability === 'live';

  const filmLink = getFilmLink(film, router, isAuthenticated);

  if (isPlayable && !forceWithoutPreviewClipPlayer) {
    return (
      <FilmTileWithPreviewPlayer
        film={film}
        filmGroupColour={filmGroupColour}
        onPlayButtonClick={onPlayButtonClick}
        showWatchListButton={showWatchListButton}
        showRatingButton={showRatingButton}
        stillImagePixelWidth={stillImagePixelWidth}
        onTileClick={onTileClick}
        showProgressBar={showProgressBar}
        progressPercentage={progressPercentage}
        listPosition={listPosition}
        programmingDateLabel={programmingDateLabel}
        backgroundColor={backgroundColor}
        filmLink={filmLink}
        onWatchlistClick={onWatchlistClick}
        filmTrackingDetails={filmTrackingDetails}
        awardsToShow={awardsToShow}
        showPlayButton={showCenteredPlayButtonAndNoHover}
      />
    );
  } else {
    return (
      <FilmTileWithoutPreviewPlayer
        film={film}
        filmGroupColour={filmGroupColour}
        showWatchListButton={showWatchListButton}
        showRatingButton={showRatingButton}
        stillImagePixelWidth={stillImagePixelWidth}
        listPosition={listPosition}
        programmingDateLabel={programmingDateLabel}
        backgroundColor={backgroundColor}
        filmLink={filmLink}
        onTileClick={onTileClick}
        onWatchlistClick={onWatchlistClick}
        awardsToShow={awardsToShow}
        showPlayButton={isPlayable && showCenteredPlayButtonAndNoHover}
        showRatingMode={showRatingMode}
        showProgressBar={showProgressBar}
        progressPercentage={progressPercentage}
      />
    );
  }
};

export default FilmTile;
