import { SyntheticEvent } from 'react';

import { Film, getIsWatchable } from '@app/api/resources/Film';
import { IndustryEventEntry } from '@app/api/resources/IndustryEvent';

import { FilmTrackingDetails } from '@app/services/snowplow';

import FilmTile from '@app/components/film-tile/default/FilmTile';
import TrackFilmImpression from '@app/components/today/TrackFilmImpression';

type FilmTileProps = {
  film: Film;
  filmGroupColour?: string;
  showWatchListButton?: boolean;
  showRatingButton?: boolean;
  stillImagePixelWidth?: number;
  onPlayButtonClick?: (e?: SyntheticEvent) => void;
  onTileClick: (e?: SyntheticEvent) => void;
  showProgressBar?: boolean;
  progressPercentage?: number;
  listPosition?: number;
  programmingDateLabel?: string;
  backgroundColor?: string;
  filmTrackingDetails?: FilmTrackingDetails;
  showRatingMode?: boolean;
  onWatchlistClick?: () => void;
  forceWithoutPreviewClipPlayer?: boolean;
  awardsToShow?: IndustryEventEntry[];
  showCenteredPlayButtonAndNoHover?: boolean;
};

const FilmTileWithImpressionTracking = ({
  film,
  filmGroupColour = '',
  onPlayButtonClick,
  showWatchListButton = true,
  showRatingButton = true,
  stillImagePixelWidth = 400,
  onTileClick,
  showProgressBar = false,
  progressPercentage = 0,
  listPosition = null,
  programmingDateLabel = null,
  backgroundColor = null,
  filmTrackingDetails,
  showRatingMode,
  forceWithoutPreviewClipPlayer,
  awardsToShow,
  showCenteredPlayButtonAndNoHover,
  onWatchlistClick,
}: FilmTileProps) => {
  return (
    <TrackFilmImpression
      filmId={film.id}
      isWatchable={getIsWatchable(film)}
      sectionNumber={filmTrackingDetails?.sectionNumber}
      indexInSection={filmTrackingDetails?.indexInSection}
      moduleType={filmTrackingDetails?.moduleType}
      horizontalPosition={filmTrackingDetails?.horizontalPosition}
      verticalPosition={filmTrackingDetails?.verticalPosition}
      collectionSlug={filmTrackingDetails?.collectionSlug}
      filmGroupId={filmTrackingDetails?.filmGroupId}
      searchRank={filmTrackingDetails?.searchRank}
    >
      <FilmTile
        film={film}
        filmGroupColour={filmGroupColour}
        showWatchListButton={showWatchListButton}
        showRatingButton={showRatingButton}
        stillImagePixelWidth={stillImagePixelWidth}
        onPlayButtonClick={onPlayButtonClick}
        onTileClick={onTileClick}
        showProgressBar={showProgressBar}
        progressPercentage={progressPercentage}
        listPosition={listPosition}
        programmingDateLabel={programmingDateLabel}
        backgroundColor={backgroundColor}
        onWatchlistClick={onWatchlistClick}
        filmTrackingDetails={filmTrackingDetails}
        showRatingMode={showRatingMode}
        forceWithoutPreviewClipPlayer={forceWithoutPreviewClipPlayer}
        awardsToShow={awardsToShow}
        showCenteredPlayButtonAndNoHover={showCenteredPlayButtonAndNoHover}
      />
    </TrackFilmImpression>
  );
};

export default FilmTileWithImpressionTracking;
